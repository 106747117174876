/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { typeOfAppointment as TOA } from '@interface/appointment-type'
import { client } from '@lib/client'
import { TOKEN } from '@lib/info'
import { useQuery } from '@tanstack/react-query'

export const typeOfAppointment = async (
  pointOfSaleId: string | undefined,
  firstStep: boolean
): Promise<TOA[]> => {
  try {
    console.log('FIRST STEP', firstStep)
    // const { data } = await client.get('/api/Service/111')
    const { data } = await client.get(`/api/Service/${TOKEN() ?? ''}`, {
      params: {
        pointOfSaleId:
          pointOfSaleId !== '' && !firstStep ? pointOfSaleId : undefined
      }
    })
    if ('data' in data) {
      return data.data
    }
    return []
  } catch (err) {
    // console.trace(err)
    return []
  }
}

export const getTypeOfAppointment = (
  pointOfSaleId: string | undefined,
  firstStep: boolean | undefined = false,
  key: string = ''
) => {
  return useQuery({
    queryFn: async () => await typeOfAppointment(pointOfSaleId, firstStep),
    queryKey: ['appointment-types', pointOfSaleId, firstStep, key],
    retry: 2,
    retryDelay: 1000
  })
}
