const reg = /^[^\w\s]/g
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const searchToken = (value: string): boolean => {
  const match = value.match(reg)
  return match !== null && match[0] === '$'
}

export const getToken = (): string => {
  const queryString = window.location.pathname
  if (queryString !== '') {
    const paths = window.location.pathname.substring(1).split('/')
    console.log(paths)
    const t = paths.find((value: string) => searchToken(value)) ?? ''
    return t
  }
  return ''
}

export const getShortLink = (): string => {
  const queryString = window.location.pathname
  if (queryString !== '') {
    const paths = window.location.pathname.substring(1).split('/')
    if (paths && paths.length > 0) return paths[0]
  }
  return ''
}
