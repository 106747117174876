export const addFavIcon = (link?: string): void => {
  console.log('FAVICON', link)
  if (link !== undefined && link !== '') {
    const linkIcon = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLAnchorElement

    if (linkIcon == null) {
      const linkIc = document.createElement('link')
      linkIc.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(linkIc)
      linkIc.href = link
    } else linkIcon.href = link
  }
}
