/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/indent */
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { selectedPage, setPage } from '@store/page'
import { setResponsive } from '@store/responsive'
import { FC, memo, useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getClient, getIndexOfStep, getInit, getUrl, setInit } from '../info'

export const Controllers: FC<{ customPath: boolean }> = memo(
  function Controllers({ customPath }): JSX.Element {
    const { steps } = getClient()
    const page = useAppSelector(selectedPage)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { pathname, search } = useLocation()

    // ? CHANGE SOME COMPONENTS VIEW ON RESIZE
    const setNewResize = useCallback(() => {
      dispatch(setResponsive(window.innerWidth))
    }, [])
    useEffect(() => {
      window.addEventListener('resize', setNewResize)
    }, [])

    // ? ON CHANGE PAGE ID NAVIGATE
    useEffect(() => {
      console.log('pageIdNavigate', `/custom${getUrl(steps[page])}${search}`)
      // navigate(`/custom${getUrl(steps[page])}${search}`)
      if (customPath) navigate(`/custom${getUrl(steps[page])}${search}`)
      else navigate(getUrl(steps[page]))
    }, [page])

    // ? MANAGE BACK
    useEffect(() => {
      if (customPath && getInit()) {
        setInit(false)
        return
      }
      let name = ''
      if (customPath) {
        name = pathname
          .substring(
            `/${process.env.REACT_APP_INITIAL_PATH ?? ''}custom/`.length
          )
          .split('/')[0]
      } else {
        name = pathname
          .substring(`/${process.env.REACT_APP_INITIAL_PATH ?? ''}`.length)
          .split('/')[0]
      }

      if (name !== undefined && name[0] !== '$') {
        const index = getIndexOfStep(name)
        if (page > index) {
          dispatch(setPage(index))
        }
      }
    }, [pathname])

    return <></>
  }
)

export const ScrollToTop = (): JSX.Element => {
  const { pathname } = useLocation()

  useEffect(() => {
    const scroll = (): void =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    setTimeout(() => scroll(), 100)
    // return setTimeout(scroll, 200)
  }, [pathname])

  return <></>
}

export const CustomControllers = memo(function Controllers(): JSX.Element {
  const { steps } = getClient()
  const page = useAppSelector(selectedPage)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname, search } = useLocation()

  // ? CHANGE SOME COMPONENTS VIEW ON RESIZE
  const setNewResize = useCallback(() => {
    dispatch(setResponsive(window.innerWidth))
  }, [])
  useEffect(() => {
    window.addEventListener('resize', setNewResize)
  }, [])

  // ? ON CHANGE PAGE ID NAVIGATE
  useEffect(() => {
    navigate(`/custom${getUrl(steps[page])}${search}`)
  }, [page])

  // ? MANAGE BACK
  useEffect(() => {
    console.log('MANAGE CUSTOM BACK')
    const name = pathname
      .substring(`/${process.env.REACT_APP_INITIAL_PATH ?? ''}custom/`.length)
      .split('/')[0]

    if (name !== undefined && name[0] !== '$') {
      const index = getIndexOfStep(name)
      if (page > index) {
        dispatch(setPage(index))
      }
    }
  }, [pathname])

  return <></>
})
