/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ShopSignLinkInterface } from '@interface/shop-sign-link'
import { client } from '@lib/client'
import { useQuery } from '@tanstack/react-query'

export const getShortLinkData = async (
  shortLink?: string
): Promise<ShopSignLinkInterface | null> => {
  try {
    if (!shortLink) return null
    const {
      data: { data }
    } = await client.get(`api/ShopSignLink/${shortLink}`)
    console.log('DATA', data)
    return data
  } catch (err) {
    console.trace(err)
    return null
  }
}

export const useShopSignLink = (shortLink?: string, key: string = '') => {
  return useQuery({
    queryFn: async () => await getShortLinkData(shortLink),
    queryKey: ['shop-sign-link', key],
    retry: 2,
    cacheTime: 300000,
    staleTime: 300000,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  })
}
