import './index.css'
import '@lib/language'

import reportWebVitals from '@app/reportWebVitals'
import { queryClient } from '@lib/react-query'
import { store } from '@store/index'
import { QueryClientProvider } from '@tanstack/react-query'
import { onInit } from '@utils/init'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
onInit().then(async (data) => {
  // console.log('--------------------------------')
  // console.dir(data)
  // console.log('--------------------------------')

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById('root')!
  const root = createRoot(container)

  root.render(
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
      {/* </React.StrictMode> */}
    </Provider>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
